// @flow

import * as React from "react";

import {ForgotPasswordPage as TablerForgotPasswordPage, LoginPage as TablerLoginPage} from "tabler-react";
import {Formik} from "formik";

type Props = {||};

function ForgotPasswordPage(props: Props): React.Node {
    return (
        <div className={"custom-authen-div"}>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};
                    if (!values.email) {
                        errors.email = "Vui lòng nhập email";
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = "Email không đúng định dạng";
                    }
                    return errors;
                }}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    // this.props.location.href = "/confirm"
                    // window.location.href  = "/confirm"
                }}
                render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                         }) => (
                    <TablerForgotPasswordPage
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        action={"/oauth/forgot" +  window.location.search}
                        method={"post"}/>
                )}
            />
        </div>
    );
}

export default ForgotPasswordPage;
