import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {
    LoginPage,
    RegisterPage,
    ForgotPasswordPage,
    Confirm,
    Error400
} from "./pages";

import "tabler-react/dist/Tabler.css";

type Props = {||};

function App(props: Props): React.Node {
    return (
        <React.StrictMode>
            <Router>
                <Switch>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/register" component={RegisterPage}/>
                    <Route exact path="/forgot" component={ForgotPasswordPage}/>
                    <Route exact path="/confirm" component={Confirm}/>
                    <Route exact path="/error400" component={Error400}/>
                </Switch>
            </Router>
        </React.StrictMode>
    );
}

export default App;
