// @flow

import * as React from "react";

// import {ConfirmCodePage as TablerConfirmCodePage} from "tabler-react";

type Props = {||};

function ConfirmCodePage(props: Props): React.Node {
    return (
        <div className={"custom-authen-div"}>
            {/*<TablerConfirmCodePage/>*/}
        </div>
    );
}

export default ConfirmCodePage;
