// @flow

import * as React from "react";
import { decode } from 'he';
import { Error400Page } from "tabler-react";

type Props = {||};

function Error400(props: Props): React.Node {
  const query = new URLSearchParams(window.location.search);
  return <Error400Page
      title={query.get('code')}
      subtitle={query.get('status')}
      details={decode(query.get('message'))}
  />;
}

export default Error400;
