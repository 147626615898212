// @flow

import * as React from "react";
import {Formik} from "formik";
import {LoginPage as TablerLoginPage} from "tabler-react";

class LoginPage extends React.Component {
    render() {
        return(
            <div className={"custom-authen-div"}>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validate={values => {
                        let errors = {};

                        if (!values.email) {
                            errors.email = "Vui lòng nhập email";
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                        ) {
                            errors.email = "Email không đúng định dạng";
                        }

                        if (!values.password) {
                            errors.password = "Vui lòng nhập mật khẩu";
                        }

                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        console.log('onSubmit')
                        // this.props.location.href = "/confirm"
                        // window.location.href  = "/confirm"
                    }}
                    render={({
                                 values,
                                 errors,
                                 touched,
                                 handleChange,
                                 handleBlur,
                                 handleSubmit,
                                 isSubmitting,
                             }) => (
                        <TablerLoginPage
                            onChange={handleChange}
                            onBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            action={"/oauth/login" +  window.location.search}
                            method={"post"}
                            buttonDisabled={errors.length > 0}
                        />
                    )}
                />
                <div className={"custom-authen-div-btn"}>
                    <a href={"/register" + window.location.search}>Đăng ký</a>
                    <a href={"/forgot" + window.location.search}>Quên mật khẩu?</a>
                </div>
            </div>
        );
    }

}

export default LoginPage;
